import React, { useState, useEffect } from "react"
import { Link } from "react-router"
import { useCookies } from "react-cookie"
import { useForm } from "react-hook-form"
import { Container, Row, Col, Button, Form } from 'react-bootstrap'

export function Disclaimer({ showDisclaimer, setShowDisclaimer }) {
    const { register, handleSubmit } = useForm()
    const cookieList = ['essential', 'google', 'cart', 'theme']
    const [cookies, setCookie, removeCookie] = useCookies(cookieList)
    const [hidden, setHidden] = useState(true)
    const denyCookies = () => {
        setHidden(true)
        setShowDisclaimer(false)
        cookieList.forEach((c) => {
            removeCookie(c, { path: '/' })
        })
    }
    useEffect(() => {
        setHidden(false)
    }, []);
    const onSubmit = data => {
        data.essential = true
        cookieList.forEach((c) => {
            if (data[c]) {
                setCookie(c, 1, {
                    path: '/',
                    sameSite: true,
                    secure: true
                })
            } else {
                removeCookie(c, { path: '/' })
            }
        })
        setShowDisclaimer(false)
    }
    const acceptAll = () => {
        cookieList.forEach((c) => {
            setCookie(c, 1, {
                path: '/',
                sameSite: true,
                secure: true
            })
        })
        setShowDisclaimer(false)
        setHidden(true)
    }
    if (!showDisclaimer && (hidden || cookies['essential'] === 1)) {
        return null
    }
    const labels = {
        'essential': 'Essentielle',
        'google': 'Google/Youtube'
    }
    return (
        <Form id="disclaimer" onSubmit={handleSubmit(onSubmit)}>
            <Container className="py-4 text-center">
                <Row>
                    <Col>
                        <p>Hier können Sie wählen, welche Cookies Sie erlauben möchten</p>
                        <Link to="/datenschutz">Mehr zum Einsatz von Cookies</Link>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        {cookieList.map((c) => {
                            if (c === 'cart' || c === 'theme') return null
                            return (
                                <Form.Check inline value="1" defaultChecked={c === 'essential' || cookies[c] === 1} disabled={c === 'essential'} key={"cookie" + c} type="switch" label={labels[c]} {...register(c)} />
                            )
                        })}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant="secondary" className="me-3 mt-2" onClick={denyCookies}>
                            Ablehnen
                        </Button>
                        <Button type="submit" className="me-3 mt-2">
                            Speichern
                        </Button>
                        <Button className="me-3 mt-2" onClick={acceptAll}>
                            Alle akzeptieren
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Form>
    )
}