import React, { useState, useEffect } from "react"
import { useParams } from "react-router"
import { useForm } from "react-hook-form"
import { faBox } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Spinner, Container, FloatingLabel, Form, Button, Row, Col } from 'react-bootstrap'
import { useAuth } from '../../components/Auth'
import FlashMessage from '../../components/FlashMessage'

export function Category() {
    let auth = useAuth()
    const { register, handleSubmit } = useForm()
    const [submitting, setSubmitting] = useState(false)
    const [category, setCategory] = useState([])
    const [saved, setSaved] = useState(null)
    const [error, setError] = useState(null)
    const [errorid, setErrorId] = useState(0)
    const [isLoaded, setIsLoaded] = useState(false)
    let { id } = useParams()

    const onSubmit = data => {
        setSubmitting(true)
        fetch(process.env.REACT_APP_API + '/categories/' + id, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(t => {
            setSubmitting(false)
            setSaved(true)
            setCategory(t)
        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setErrorId(errorid + 1)
            setSubmitting(false)
        });
    }
    const fetchCategory = function (id, token) {
        fetch(process.env.REACT_APP_API + '/categories/' + id, {
            method: 'GET',
            cache: 'no-store',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Cache-Control": "no-cache",
                "Pragma": "no-cache",
                "Authorization": "Bearer " + token
            },
        })
            .then(res => res.json())
            .then(
                (res) => {
                    if (res.detail) {
                        setError({ message: res.detail })
                    } else {
                        setCategory(res)
                    }
                    setIsLoaded(true)
                },
                (error) => {
                    setIsLoaded(true)
                    setError(error)
                }
            )
    }
    useEffect(() => {
        fetchCategory(id, auth.token);
    }, [id, auth])
    return (
        <Container className="d-flex flex-column">
            <h1 className="my-5"><FontAwesomeIcon icon={faBox} /> Kategorie bearbeiten</h1>
            <Row className="w-100">
                <Col>
                    {isLoaded ? (
                        <form className="form-signin text-center w-100" onSubmit={handleSubmit(onSubmit)}>
                            <input type="hidden" {...register('id', { required: true })} value={category.id} />
                            <FloatingLabel
                                label="Name *"
                                className="mb-3"
                            >
                                <Form.Control {...register('name', { required: true })} defaultValue={category.name} type="text" placeholder="Name" required />
                            </FloatingLabel>
                            <FloatingLabel
                                label="Sortierung *"
                                className="mb-3"
                            >
                                <Form.Control {...register('sort', { required: true, min: 0 })} defaultValue={category.sort} type="number" placeholder="Sortierung" required />
                            </FloatingLabel>
                            <p className="text-end">
                                <small>* Pflichtfelder</small>
                            </p>
                            {error && (
                                <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true} key={errorid}>
                                    <Alert variant="danger">
                                        <span>
                                            {error.message} {error.detail}
                                        </span>
                                    </Alert>
                                </FlashMessage>
                            )}
                            {saved && (
                                <FlashMessage duration={2000}>
                                    <Alert variant="success">
                                        <span>
                                            Kategorie erfolgreich aktualisiert
                                        </span>
                                    </Alert>
                                </FlashMessage>
                            )}
                            <div className="d-grid gap-2">
                                <Button disabled={submitting} variant="primary" type="submit">
                                    {submitting ? (
                                        <Spinner animation="border" variant="light" size="sm"></Spinner>
                                    ) : "Aktualisieren"}
                                </Button>
                            </div>
                        </form>
                    ) : <div className="text-center">
                        <Spinner />
                    </div>}
                </Col>
            </Row>
        </Container>
    )
}