import React, { useState, useEffect } from "react"
import { useParams, Link } from "react-router"
import { useForm } from "react-hook-form"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getMainImage } from '../../components/Util'
import { Alert, Spinner, Container, FloatingLabel, Form, Row, Col, Table, Button } from 'react-bootstrap'
import { faFilePdf, faRetweet, faTrash, faSpinner, faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scrollToElement from 'scroll-to-element'
import { useAuth } from '../../components/Auth'
import FlashMessage from '../../components/FlashMessage'
import { Time } from '../../components/Time'
import { fetchStati } from '../../requests/Stati'
import { fetchReasons } from '../../requests/Reasons'
import { loadLeihschein } from '../../requests/Leihschein'
import { getFullname, devlog } from '../../components/Util'

export function AdminRental() {
    let auth = useAuth()
    const { register, handleSubmit } = useForm()
    const [stati, setStati] = useState([])
    const [reasons, setReasons] = useState([])
    const [submitting, setSubmitting] = useState(false)
    const [rental, setRental] = useState([])
    const [saved, setSaved] = useState(null)
    const [error, setError] = useState(null)
    const [errorid, setErrorId] = useState(0)
    const [isLoaded, setIsLoaded] = useState(false)
    const [loadingpdf, setLoadingpdf] = useState(0)
    let { id } = useParams()
    const onSubmit = data => {
        setSubmitting(true)
        data.devices = []
        rental.devices.forEach((d) => {
            data.devices.push(d.id)
        })
        fetch(process.env.REACT_APP_API + '/rentals/' + id, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(r => {
            setSubmitting(false);
            setSaved(true)
            setRental(r)
        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setErrorId(errorid + 1)
            setSubmitting(false)
        })
    }
    const removeItemFromRental = (device_id, title) => {
        if (window.confirm(title + "' wirklich entfernen?")) {
            fetch(process.env.REACT_APP_API + '/rentals/' + id + '/' + device_id, {
                method: 'DELETE',
                cache: 'no-cache',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + auth.token
                },
            })
                .then(
                    (res) => {
                        if (!res.ok) {
                            if (res.status === 409) {
                                setError({ message: 'Das Objekt wird noch verwendet und kann nicht gelöscht werden' });
                                setTimeout(() => {
                                    setError(null)
                                }, 5000)
                            }
                        } else {
                            setRental(res)
                        }
                    },
                    (error) => {
                        setError(error);
                    }
                )
        }
    }
    const fetchRental = function (abortController, id, token) {
        fetch(process.env.REACT_APP_API + '/rentals/' + id, {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token
            },
        })
            .then(res => res.json())
            .then(
                (r) => {
                    if (r.detail) {
                        setError({ message: r.detail })
                    } else {
                        setRental(r)
                        fetchReasons(abortController, setReasons, setError, function () {
                            fetchStati(abortController, setStati, setIsLoaded, setError)
                        });
                    }
                },
                (error) => {
                    if (abortController.signal.aborted) {
                        devlog('The user aborted the request');
                    } else {
                        setError(error)
                    }
                }
            )
    }
    useEffect(() => {
        const abortController = new AbortController()
        fetchRental(abortController, id, auth.token)
        return () => {
            abortController.abort();
        }
    }, [id, auth.token])

    return (
        <Container className="d-flex flex-column">
            <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
                <Row className="my-5">
                    <Col xs={8}>
                        <h1>
                            <FontAwesomeIcon icon={faRetweet} />
                            {rental.related_id > 0 && (
                                <> Cooldown </>
                            )}
                            Vorgang #{id}
                            {rental.related_id > 0 && (
                                <> für <Link to={"/admin/rentals/" + rental.related_id}>#{rental.related_id}</Link></>
                            )}
                        </h1>
                    </Col>
                    <Col xs={4} className="text-end pe-5">
                        <Button className="me-2" title={"Leihschein " + rental.id} disabled={loadingpdf} onClick={() => loadLeihschein(rental.id, auth.token, setLoadingpdf)}>
                            <FontAwesomeIcon className={loadingpdf ? 'spin' : ''} icon={loadingpdf ? faSpinner : faFilePdf} />
                        </Button>
                        <Button title={"Aktualisieren"} disabled={submitting} type="submit" onClick={() => {
                            scrollToElement('#submitbutton', {
                                offset: -80,
                                duration: 200
                            })
                        }}>
                            <FontAwesomeIcon className={submitting ? 'spin' : ''} icon={submitting ? faSpinner : faFloppyDisk} />
                        </Button>
                    </Col>
                </Row>
                <Row className="w-100">
                    <Col>
                        {isLoaded ? (
                            <>
                                <input type="hidden" {...register('id', { required: true })} value={rental.id} />
                                <input type="hidden" {...register('user_id', { required: true })} value={rental.user_id} />
                                <input type="hidden" {...register('related_id', { required: true })} value={rental.related_id} />
                                <Row>
                                    <Col>
                                        <FloatingLabel
                                            label="Nutzer"
                                            className="mb-3"
                                        >
                                            <Form.Control defaultValue={getFullname(rental.user)} type="text" placeholder="Vorname" readOnly />
                                        </FloatingLabel>
                                    </Col><Col>
                                        <FloatingLabel
                                            label="Status *"
                                            className="mb-3"
                                        >
                                            <Form.Select {...register('status_id', { required: true })} defaultValue={rental.status.id} aria-label="Status">
                                                {stati.map((s, i) => {
                                                    return <option key={'statusoption' + s.id} value={s.id}>{s.name}</option>
                                                })}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row className="readonly">
                                    <Col>
                                        <FloatingLabel
                                            label="Von *"
                                            className="mb-3"
                                        >
                                            <Form.Control {...register('start', { required: true })} readOnly defaultValue={rental.start} type="datetime-local" placeholder="Von *" required />
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel
                                            label="Bis *"
                                            className="mb-3"
                                        >
                                            <Form.Control {...register('end', { required: true })} readOnly defaultValue={rental.end} type="datetime-local" placeholder="Von *" required />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FloatingLabel
                                            label="Einsatzzweck *"
                                            className="mb-3"
                                        >
                                            <Form.Select {...register('reason_id', { required: true })} defaultValue={rental.reason.id} aria-label="Einsatzzweck">
                                                {reasons.map((r, i) => {
                                                    return <option key={'reasonoption' + r.id} value={r.id}>{r.name}</option>
                                                })}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="text-start" controlId="formBasicCheckbox">
                                            <Form.Check {...register('tutorial', {})} inline value={true} defaultChecked={rental.tutorial} type="switch" label="Einweisung erwünscht" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="text-start" controlId="formBasicCheckbox">
                                            <Form.Check {...register('pickup', {})} inline value={true} defaultChecked={rental.pickup} type="switch" label="Selbstabholer" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col className="readonly">
                                        <FloatingLabel
                                            label="Bemerkungen"
                                            className="mb-3"
                                        >
                                            <Form.Control {...register('bemerkungen', {})} readOnly defaultValue={rental.bemerkungen} as="textarea" placeholder="Bemerkungen" />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FloatingLabel
                                            label="Interne Anmerkungen"
                                            className="mb-3"
                                        >
                                            <Form.Control {...register('interna', {})} defaultValue={rental.interna} as="textarea" placeholder="Interne Anmerkungen" rows={5} />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Table striped hover>
                                    <thead>
                                        <tr className="text-start">
                                            <th>Bild</th>
                                            <th>Produkt</th>
                                            <th>Gerätenummer</th>
                                            <th>Standort</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(rental.devices) && rental.devices.map((device, n) => (
                                            <tr key={"cartrow" + n} className="text-start">
                                                <td>
                                                    <Link to={"/products/" + device.product.id}>
                                                        <LazyLoadImage effect="opacity" width={200} className="img-fluid" src={getMainImage(device.product)} alt={device.name} />
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link to={"/products/" + device.product.id}>{device.product.name}</Link>
                                                </td>
                                                <td>
                                                    {device.serial}
                                                </td>
                                                <td>
                                                    {device.location.plz} {device.location.ort}
                                                </td>
                                                <td className="text-end">
                                                    <button type="button" className="btn btn-primary btn-xs ms-2" onClick={() => { removeItemFromRental(device.id, device.product.name + " (" + device.serial + ")") }}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <Row>
                                    <Col className="text-start">
                                        <Table size="sm" striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Datum</th>
                                                    <th>Mitarbeiter</th>
                                                    <th>Nachricht</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rental.logs.sort((a, b) => { return a.id < b.id }).map((l) => {
                                                    return <tr key={'rentallog' + l.id}>
                                                        <td>{Time({
                                                            date: l.created,
                                                            withTime: true
                                                        })}</td>
                                                        <td>{getFullname(l.user)}</td>
                                                        <td>{l.message}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                {error && (
                                    <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true} key={errorid}>
                                        <Alert variant="danger">
                                            <span>
                                                {error.message} {error.detail}
                                            </span>
                                        </Alert>
                                    </FlashMessage>
                                )}
                                {saved && (
                                    <FlashMessage duration={2000}>
                                        <Alert variant="success">
                                            <span>
                                                Vorgang erfolgreich aktualisiert
                                            </span>
                                        </Alert>
                                    </FlashMessage>
                                )}
                                <div className="d-grid gap-2">
                                    <Button id="submitbutton" disabled={submitting} variant="primary" type="submit">
                                        {submitting ? (
                                            <Spinner animation="border" variant="light" size="sm"></Spinner>
                                        ) : "Aktualisieren"}
                                    </Button>
                                </div>
                            </>) : <div className="text-center">
                            <Spinner />
                        </div>}
                    </Col>
                </Row>
            </form>
        </Container>
    )
}