import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import { Alert, Spinner, Container, FloatingLabel, Form, Button, Row, Col } from 'react-bootstrap'
import { useAuth } from '../../components/Auth'
import FlashMessage from '../../components/FlashMessage'

export function UserCreate() {
    let auth = useAuth()
    const navigate = useNavigate()
    const { register, handleSubmit, getValues, formState: { errors } } = useForm()
    const [submitting, setSubmitting] = useState(false)
    const [user, setUser] = useState([])
    const [roles, setRoles] = useState([])
    const [saved, setSaved] = useState(null)
    const [error, setError] = useState(null)
    const [errorid, setErrorId] = useState(0)
    const [isLoaded, setIsLoaded] = useState(false)

    const handleRoleButtonClick = (role_id, e) => {
        if (role_id === 1 && !auth.scopes.includes("admin")) {
            e.preventDefault()
        }
    }
    const fetchRoles = function (token) {
        fetch(process.env.REACT_APP_API + '/roles/', {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token
            },
        })
            .then(res => res.json())
            .then(
                (res) => {
                    setRoles(res)
                    setIsLoaded(true)
                },
                (error) => {
                    setError(error)
                }
            )
    }
    const onSubmit = data => {
        setSubmitting(true)
        fetch(process.env.REACT_APP_API + '/users/', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(u => {
            setSubmitting(false)
            setSaved(true)
            setUser(u)
            setTimeout(function () {
                navigate({ pathname: "/admin/users/" })
            }, 1000)
        }).catch((e) => {
            setError({ message: e.name, detail: e.message })
            setErrorId(errorid + 1)
            setSubmitting(false)
        });
    }
    useEffect(() => {
        fetchRoles(auth.token)
    }, [auth])
    return (
        <Container className="d-flex flex-column">
            <h1 className="my-5">Nutzer erstellen</h1>
            <Row className="w-100">
                <Col>
                    {isLoaded ? (
                        <form className="form-signin text-center w-100" onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col>
                                    <FloatingLabel
                                        label="Vorname *"
                                        className="mb-3"
                                    >
                                        <Form.Control {...register('firstname', { required: true })} defaultValue={user.firstname} type="text" placeholder="Vorname" required />
                                    </FloatingLabel>
                                </Col><Col>
                                    <FloatingLabel
                                        label="Nachname *"
                                        className="mb-3"
                                    >
                                        <Form.Control {...register('lastname', { required: true })} defaultValue={user.lastname} type="text" placeholder="Nachname" required />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FloatingLabel
                                        label="Einrichtung"
                                        className="mb-3"
                                    >
                                        <Form.Control {...register('einrichtung', {})} defaultValue={user.einrichtung} type="text" placeholder="Einrichtung" />
                                    </FloatingLabel>
                                </Col><Col>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FloatingLabel
                                        label="PLZ"
                                        className="mb-3"
                                    >
                                        <Form.Control {...register('plz', {})} defaultValue={user.plz} type="text" placeholder="PLZ" />
                                    </FloatingLabel>
                                </Col><Col>
                                    <FloatingLabel
                                        label="Ort"
                                        className="mb-3"
                                    >
                                        <Form.Control {...register('ort', {})} defaultValue={user.ort} type="text" placeholder="Ort" />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FloatingLabel
                                        label="Straße"
                                        className="mb-3"
                                    >
                                        <Form.Control {...register('street', {})} defaultValue={user.street} type="text" placeholder="Straße" />
                                    </FloatingLabel>
                                </Col><Col>
                                    <FloatingLabel
                                        label="Hausnummer"
                                        className="mb-3"
                                    >
                                        <Form.Control {...register('streetnum', {})} defaultValue={user.streetnum} type="text" placeholder="Hausnummer" />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FloatingLabel
                                        label="Email *"
                                        className="mb-3"
                                    >
                                        <Form.Control {...register('email', { required: true })} defaultValue={user.email} type="email" placeholder="name@example.com" autoFocus required />
                                    </FloatingLabel>
                                    <Form.Group className="text-start" controlId="formBasicCheckbox">
                                        <Form.Check {...register('email_confirmed', {})} inline value={true} defaultChecked={user.email_confirmed} type="switch" label={<p className="w-100">Email bestätigt</p>} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <FloatingLabel
                                        label="Telefon"
                                        className="mb-3"
                                    >
                                        <Form.Control {...register('tel', {})} defaultValue={user.tel} type="text" placeholder="Telefon" />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FloatingLabel
                                        label="Passwort ändern *"
                                        className="mb-3"
                                    >
                                        <Form.Control className={errors.password ? 'is-invalid' : ''} {...register('password', { required: true, minLength: 8 })} type="password" placeholder="Passwort ändern" />
                                        {errors.password && <div className="invalid-feedback text-start">Bitte verwenden Sie mindestens 8 Zeichen</div>}
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel
                                        label="Passwort Verfifikation *"
                                        className="mb-3"
                                    >
                                        <Form.Control className={errors.password2 ? 'is-invalid' : ''} {...register('password2', { required: true, minLength: 8, validate: value => value === getValues("password") })} type="password" placeholder="Passwort Verfifikation" />
                                        {errors.password2 && <div className="invalid-feedback text-start">Bitte überprüfen Sie die Schreibweise Ihres Passwortes</div>}
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h2>Rollen</h2>
                                    <Row>
                                        {roles.map((role, i) => {
                                            if (role.id !== 1 || auth.scopes.includes("admin")) {
                                                return (
                                                    <Col key={"role" + role.id}>
                                                        <Form.Group className="text-center" controlId="formBasicCheckbox" key={role.id}>
                                                            <Form.Check {...register('roles[' + i + ']', {})} inline readOnly={!auth.scopes.includes('admin') && role.id === 1} value={role.id} onClick={(e) => handleRoleButtonClick(role.id, e)} defaultChecked={role.id === 3} type="switch" label={<p className="w-100">{role.name}</p>} />
                                                        </Form.Group>
                                                    </Col>
                                                )
                                            }
                                            return <input key={role.id} {...register('roles[' + i + ']')} type="hidden" value="0" />
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <FloatingLabel
                                        label="Bemerkungen"
                                        className="mb-3"
                                    >
                                        <Form.Control {...register('interna', {})} defaultValue={user.interna} as="textarea" placeholder="Bemerkungen" />
                                    </FloatingLabel>
                                    <p className="text-end">
                                        <small>* Pflichtfelder</small>
                                    </p>
                                </Col>
                            </Row>
                            <p className="text-end">
                                <small>* Pflichtfelder</small>
                            </p>
                            {error && (
                                <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true} key={errorid}>
                                    <Alert variant="danger">
                                        <span>
                                            {error.message} {error.detail}
                                        </span>
                                    </Alert>
                                </FlashMessage>
                            )}
                            {saved && (
                                <FlashMessage duration={2000}>
                                    <Alert variant="success">
                                        <span>
                                            Nutzer erfolgreich erstellt
                                        </span>
                                    </Alert>
                                </FlashMessage>
                            )}
                            <div className="d-grid gap-2">
                                <Button disabled={submitting} variant="primary" type="submit">
                                    {submitting ? (
                                        <Spinner animation="border" variant="light" size="sm"></Spinner>
                                    ) : "Erstellen"}
                                </Button>
                            </div>
                        </form>
                    ) : <div className="text-center">
                        <Spinner />
                    </div>}
                </Col>
            </Row>
        </Container>
    )
}