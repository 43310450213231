import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router"
import { useAuth } from '../components/Auth'
import FlashMessage from '../components/FlashMessage'
import { fetchProduct } from '../requests/Product'
import { Alert, Container, Row, Col, Spinner } from 'react-bootstrap'

export function Qr() {
    let auth = useAuth()
    const navigate = useNavigate()
    let { id, device_id } = useParams()
    const [product, setProduct] = useState([])
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        fetchProduct(id, auth.token, setProduct, setError, null, null, setIsLoaded);
    }, [auth.token, id])
    return (
        <Container>
            <Row>
                <Col>
                    {error && (
                        <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true}>
                            <Alert variant="danger">
                                <span>
                                    {error.message} {error.detail}
                                </span>
                            </Alert>
                        </FlashMessage>
                    )}
                    {isLoaded ? (
                        auth.scopes.includes("mitarbeiter") ? (
                            device_id ? navigate({ pathname: "/admin/devices/" + device_id }) :
                                navigate({ pathname: "/admin/products/" + product.id })
                        ) : navigate({ pathname: "/products/" + product.id })

                    ) :
                        <div className="text-center">
                            <Spinner />
                        </div>
                    }
                </Col>
            </Row>
        </Container>
    )
}