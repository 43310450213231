import React, { useState } from "react"
import { Navigate } from "react-router"
import { useAuth } from './Auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

export function Logout() {
    const [navigate, setNavigate] = useState(false)
    let auth = useAuth()

    const logout = (e) => {
        e.preventDefault()
        auth.signout(function () {
            setNavigate(true)
        })
    }
    if (navigate) {
        return <Navigate to={"/"} />
    }
    return (
        <a title="Ausloggen" className="btn btn-primary btn-lg" href="/logout" onClick={logout}><FontAwesomeIcon icon={faSignOutAlt} /> Ausloggen</a>
    )
}