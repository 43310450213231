import { Link } from "react-router"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSnowflake } from '@fortawesome/free-solid-svg-icons'
import { checkIsBlocked } from './Util'
import { useCart } from './Cart'

export const PlanDeviceRow = ({ device, year, month, lastDay }) => {
    let cart = useCart()
    return (
        <tr>
            <td>
                <Link to={"/admin/devices/" + device.id}>{device.product.name}<br /><small>{device.serial}</small></Link>
            </td>
            {[...Array(lastDay).keys()].map((day) => {
                var datum = new Date()
                datum.setFullYear(year)
                datum.setDate(day + 1)
                datum.setMonth(month)
                const isBlocked = checkIsBlocked(device, datum)
                const key = "pdr" + device.id + "-" + month + "-" + day
                const inCart = cart.isInCart(device.id, datum)
                return (isBlocked.rental_id > 1 ? (
                    <td key={key} className={(inCart ? "incart " : "") + "reserved link text-end"}>
                        <Link className="p-1 px-2" title={"Vorgang #" + isBlocked.rental_id} to={"/admin/rentals/" + isBlocked.rental_id}>
                            {isBlocked.cooldown ? <FontAwesomeIcon className="text-white" icon={faSnowflake} /> : " "}
                        </Link>
                    </td>) : isBlocked ? <td key={key} className={(inCart ? "incart " : "") + "blocked link"}><Link title={"Gerät " + device.serial + " ist deaktiviert"} to={"/admin/devices/" + device.id}></Link></td>
                    : <td key={key} className={(inCart ? "incart " : "") + "link"}>
                        {inCart ? (
                            <Link title={device.product.name + " (" + device.serial + ") aus Reservierung entfernen"} onContextMenu={(e) => {
                                e.preventDefault()
                                datum.setHours(23)
                                datum.setMinutes(59)
                                cart.unbouncedUpdateCart('end', datum, true)
                            }}
                                onClick={(e) => {
                                    if (window.confirm(device.product.name + " (" + device.serial + ") wirklich aus dem Warenkorb entfernen?")) {
                                        e.preventDefault();
                                        cart.removeDevice(device.id)
                                    }
                                }}></Link>
                        ) : (
                            <Link title={device.product.name + " (" + device.serial + ") zur Reservierung hinzufügen"} onContextMenu={(e) => {
                                e.preventDefault()
                                datum.setHours(23)
                                datum.setMinutes(59)
                                cart.unbouncedUpdateCart('end', datum, true)
                            }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    cart.addDevice(device.id, datum)
                                }}></Link>
                        )}
                    </td>
                )
            })}

        </tr>
    )
}