import React, { useEffect, useState } from "react"
import { useNavigate, Link } from "react-router"
import { useForm } from "react-hook-form"
import { useDebouncedCallback } from 'use-debounce'
import { useAuth } from '../components/Auth'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getMainImage, dateToDateTimeLocale, getFullname } from '../components/Util'
import FlashMessage from '../components/FlashMessage'
import { fetchReasons } from '../requests/Reasons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCartShopping } from '@fortawesome/free-solid-svg-icons'
import { Button, Row, Col, Spinner, Alert, Table, Form, FloatingLabel, Modal } from 'react-bootstrap'

export function CartModal({ id, show, setShow, basket, removeDevice, clearCart, updateCart, flattenDevices }) {
    let auth = useAuth();
    const navigate = useNavigate()
    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm()
    const [submitting, setSubmitting] = useState(false)
    const [reasons, setReasons] = useState([])
    const [error, setError] = useState(null)
    const [errorid, setErrorId] = useState(0)
    const [isLoaded, setIsLoaded] = useState(false)
    const [saved, setSaved] = useState(false)
    const [collisions, setCollisions] = useState([])
    const [searchingUser, setSearchingUser] = useState(false)
    const [users, setUsers] = useState([])

    const removeFromCart = (id, title) => {
        if (window.confirm("'" + title + "' wirklich aus dem Warenkorb entfernen?")) {
            removeDevice(id)
        }
    }
    const clearThisCart = (shallConfirm) => {
        clearCart(shallConfirm)
    }
    const searchUser = useDebouncedCallback((u) => {
        if (u.length < 2) {
            return true
        }
        setSearchingUser(true)
        fetch(process.env.REACT_APP_API + '/users/search', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify({ user: u })
        }).then(async res => {
            if (!res.ok) {
                throw new Error(res.detail)
            } else {
                return res.json()
            }
        }).then(users => {
            setSearchingUser(false)
            setUsers(users)
            if (users.length === 0) {
                reset({
                    user_id: 0
                })
            }

        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setErrorId(errorid + 1)
            setSearchingUser(false)
        });
    }, 300)
    const onSubmit = (data) => {
        setSubmitting(true);
        data.status_id = 1
        data.interna = ''
        data.related_id = 0
        data.devices = flattenDevices(basket.items)
        var url = process.env.REACT_APP_API + '/rentals/me/'
        if (auth.scopes.includes("mitarbeiter")) {
            url = process.env.REACT_APP_API + '/rentals/create/'
        } else {
            data.user_id = 0
        }
        fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(async res => {
            if (!res.ok) {
                if (res.status === 409) {
                    const j = await res.json()
                    setCollisions(j.devices)
                    throw new Error(j.detail)
                }
                throw new Error(res.detail)
            } else {
                return res.json()
            }
        }).then(t => {
            setSubmitting(false)
            setSaved(true)
            clearCart(false)
            setTimeout(function () {
                navigate({ pathname: "/rentals/" });
            }, 1000);
        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setErrorId(errorid + 1)
            setSubmitting(false)
        });
    }
    useEffect(() => {
        const abortController = new AbortController()
        if (!isLoaded && show) {
            fetchReasons(abortController, setReasons, setError, () => {
                setIsLoaded(true)
            });
        }
        return () => {
            abortController.abort();
        }
    }, [isLoaded, show])

    useEffect(() => {
        reset()
    }, [id, reset])
    return (
        <Modal
            className="cart-modal"
            show={show}
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Warenkorb</Modal.Title>
                <Button variant="danger" className="ms-auto" onClick={(e) => clearThisCart(true)}><FontAwesomeIcon icon={faTrash} /></Button>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-4">
                    <Col>
                        {isLoaded ? (
                            <>
                                <form className="form-signin text-center w-100" onSubmit={handleSubmit(onSubmit)}>
                                    <Table striped hover>
                                        <thead>
                                            <tr className="text-start">
                                                <th>Bild</th>
                                                <th>Produkt</th>
                                                <th>Gerätenummer</th>
                                                <th>Standort</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {basket !== null && Array.isArray(basket.items) && basket.items.map((item, n) => (
                                                <tr key={"cartrow" + n} className={(collisions.includes(item.device?.id) ? 'unavailable ' : '') + "text-start"}>
                                                    <td>
                                                        <Link to={"/products/" + item.device?.product.id}>
                                                            <LazyLoadImage effect="opacity" width={200} className="img-fluid" src={getMainImage(item.device?.product)} alt={item.device?.name} />
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to={"/products/" + item.device?.product.id}>
                                                            {item.device?.product.name}
                                                            {item.device?.product.parent && (
                                                                <><br />({item.device.product.parent.name})</>
                                                            )}

                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {item.device?.serial}
                                                    </td>
                                                    <td>
                                                        {item.device?.location.plz} {item.device?.location.ort}
                                                    </td>
                                                    <td className="text-end">
                                                        <button type="button" className="btn btn-primary btn-xs ms-2" onClick={() => { removeFromCart(item.device?.id, item.device?.serial) }}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    {auth.scopes.includes("mitarbeiter") && (
                                        <Row>
                                            <Col>
                                                <FloatingLabel
                                                    label="Nutzer suchen"
                                                    className="mb-3"
                                                >
                                                    <Form.Control type="text" placeholder="Nutzer suchen" onKeyUp={(e) => searchUser(e.target.value)} onChange={(e) => searchUser(e.target.value)} autoFocus />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                {searchingUser ? <Spinner className="mt-3" /> : (
                                                    <FloatingLabel
                                                        label="Nutzer *"
                                                        className="mb-3"
                                                    >
                                                        {users.length > 0 ? (
                                                            <Form.Select {...register('user_id', { required: true, min: 1 })} defaultValue={0} className={errors.user_id ? 'is-invalid' : ''} aria-label="Nutzer">
                                                                <option value="0">{users.length} Treffer</option>
                                                                {users.map((u, i) => {
                                                                    return <option key={'useroption' + u.id} value={u.id}>{getFullname(u)}</option>
                                                                })}
                                                            </Form.Select>
                                                        ) : (
                                                            <Form.Select {...register('user_id', { required: true, min: 1 })} defaultValue={0} className={'is-invalid'} aria-label="Nutzer">
                                                                <option value="0">keine Treffer</option>
                                                            </Form.Select>
                                                        )}
                                                    </FloatingLabel>
                                                )}
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col>
                                            <FloatingLabel
                                                label="Von *"
                                                className="mb-3"
                                            >
                                                <Form.Control className={errors.start ? 'is-invalid' : ''} {...register('start', { required: true, validate: { beforeEnd: v => { const end = getValues('end'); return new Date(v) < new Date(end) } } })} onChange={(e) => updateCart('start', e.target.value, false)} defaultValue={dateToDateTimeLocale(basket?.start)} type="datetime-local" required />
                                                {errors.start && <div className="invalid-feedback text-start">Das Datum liegt nicht vor dem Enddatum</div>}
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel
                                                label="Bis *"
                                                className="mb-3"
                                            >
                                                <Form.Control className={errors.end ? 'is-invalid' : ''} {...register('end', { required: true, validate: { beforeStart: v => { const start = getValues('start'); return new Date(v) > new Date(start) } } })} onChange={(e) => updateCart('end', e.target.value, false)} defaultValue={dateToDateTimeLocale(basket?.end)} type="datetime-local" required />
                                                {errors.end && <div className="invalid-feedback text-start">Das Datum liegt nicht nach dem Startdatum</div>}
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <FloatingLabel
                                        label="Bemerkungen"
                                        className="mb-3"
                                    >
                                        <Form.Control {...register('bemerkungen', {})} onChange={(e) => updateCart('bemerkungen', e.target.value, false)} defaultValue={basket?.bemerkungen} as="textarea" placeholder="Bemerkungen" />
                                    </FloatingLabel>
                                    <Row>
                                        <Col>
                                            <Form.Group className="text-start" controlId="formBasicCheckbox">
                                                <Form.Check {...register('pickup', {})} inline onChange={(e) => updateCart('pickup', e.target.value, false)} value={true} defaultChecked={basket?.pickup} type="switch" label="Ich hole selbst ab" />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="text-start" controlId="formBasicCheckbox">
                                                <Form.Check {...register('tutorial', {})} inline onChange={(e) => updateCart('tutorial', e.target.value, false)} value={true} defaultChecked={basket?.tutorial} type="switch" label="Ich wünsche eine Einführung" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col>
                                            <FloatingLabel
                                                label="Einsatzzweck *"
                                                className="mb-3"
                                            >
                                                <Form.Select {...register('reason_id', { required: true, min: 2 })} onChange={(e) => updateCart('reason_id', e.target.value, false)} defaultValue={basket?.reason_id} className={errors.reason_id ? 'is-invalid' : ''} aria-label="Einsatzzweck">
                                                    {reasons.map((r, i) => {
                                                        if (r.id === 7) return ''
                                                        return <option key={'reasonoption' + r.id} value={r.id}>{r.name}</option>
                                                    })}
                                                </Form.Select>
                                                {errors.reason_id && <div className="invalid-feedback text-start">Bitte nennen Sie uns den geplanten Einsatzzweck</div>}
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    {saved && (
                                        <FlashMessage duration={2000}>
                                            <Alert variant="success">
                                                <span>
                                                    Anfrage erfolgreich gespeichert
                                                </span>
                                            </Alert>
                                        </FlashMessage>
                                    )}
                                    {error && (
                                        <FlashMessage key={errorid} duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true}>
                                            <Alert variant="danger">
                                                <span>
                                                    {error.detail}
                                                </span>
                                            </Alert>
                                        </FlashMessage>
                                    )}
                                    <div className="d-grid gap-2 mt-4">
                                        <Button className="hover-zoom" disabled={submitting || basket?.items.length === 0} variant="primary" type="submit">
                                            {submitting ? (
                                                <Spinner animation="border" variant="light" size="sm"></Spinner>
                                            ) : (
                                                <span><FontAwesomeIcon icon={faCartShopping} /> Anfrage starten</span>
                                            )}
                                        </Button>
                                    </div>
                                </form>
                            </>
                        ) :
                            <div className="text-center">
                                <Spinner />
                            </div>
                        }
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}