import React, { useState, useEffect, lazy, useCallback, Suspense } from "react"
import { useParams, Link } from "react-router"
import { useForm } from "react-hook-form"
import { FileUploader } from "react-drag-drop-files"
import scrollToElement from 'scroll-to-element'
import { faVideo, faTags, faFilm, faBook, faPen, faPlus, faTrash, faCloudArrowUp, faEye, faEyeSlash, faFloppyDisk, faBoxesStacked } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Spinner, Container, FloatingLabel, Form, Button, Row, Col, Card, Table } from 'react-bootstrap'
import { useAuth } from '../../components/Auth'
import FlashMessage from '../../components/FlashMessage'
import { ManualModal } from '../../components/ManualModal'
import { ProductChildModal } from '../../components/ProductChildModal'
import { VideoModal } from '../../components/VideoModal'
import { AdminImage } from "../../components/AdminImage"
import { deleteItem } from "../../components/Util"
import { fetchProduct } from '../../requests/Product'
import { fetchCategories } from '../../requests/Categories'

const MDEditor = lazy(() => import('@uiw/react-md-editor'))

export function AdminProduct() {
    let auth = useAuth()
    const { register, handleSubmit, reset, watch } = useForm()

    const [showVideoModal, setShowVideoModal] = useState(false)
    const [showManualModal, setShowManualModal] = useState(false)
    const [showChildModal, setShowChildModal] = useState(false)

    const fileTypes = ["JPG", "PNG", "GIF", "WEBP"]
    const [uploading, setUploading] = useState(false)
    const [invalidTeaser, setInvalidTeaser] = useState(false)
    const [teaser, setTeaser] = useState("")
    const [description, setDescription] = useState("")
    const [submitting, setSubmitting] = useState(false)
    const [product, setProduct] = useState([])
    const [categories, setCategories] = useState([])
    const [tags, setTags] = useState([])
    const [saved, setSaved] = useState(null)
    const [error, setError] = useState(null)
    const [errorid, setErrorId] = useState(0)
    const [isLoaded, setIsLoaded] = useState(false)
    const watchProductSet = watch("setparent")
    let { id } = useParams()
    const validateTeaser = (t) => {
        const limit = 150
        setInvalidTeaser(t.length > limit)
        setTeaser(t.substring(0, limit))
    }
    const isInTag = (tag_id) => {
        if (Array.isArray(product.tags)) {
            for (var i = 0; i < product.tags.length; i++) {
                if (product.tags[i].id === tag_id) {
                    return true
                }
            }
        }
        return false
    }
    const uploadFile = (f) => {
        setUploading(true)
        const formData = new FormData()
        formData.append('image', f)
        fetch(process.env.REACT_APP_API + '/images/' + product.id, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: formData
        }).then(res => {
            if (!res.ok) {
                throw new Error('Bild konnte nicht gespeichert werden')
            }
            return res.json()
        }).then(d => {
            fetchProduct(id, auth.token, setProduct, setError, setTeaser, setDescription, setIsLoaded)
            setUploading(false)
        }).catch((e) => {
            setError({ message: e.name, detail: e.message })
            setUploading(false)
            setErrorId(errorid + 1)
            setSubmitting(false)
        })
    }
    const onSubmit = data => {
        setSubmitting(true)
        data.teaser = teaser
        data.description = description
        data.parent_id = -1
        if (product.parent_id > 0) {
            data.parent_id = product.parent_id
        }
        data.children = []
        fetch(process.env.REACT_APP_API + '/products/' + id, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(t => {
            setSubmitting(false)
            setSaved(true)
            setProduct(t)
        }).catch((e) => {
            setError({ message: e.name, detail: e.message })
            setErrorId(errorid + 1)
            setSubmitting(false)
        })
    }
    const deleteRelation = (child_id, title) => {
        if (window.confirm("'" + title + "' wirklich aus dem Set entfernen?")) {
            fetch(process.env.REACT_APP_API + '/products/' + product.id + '/' + child_id, {
                method: 'DELETE',
                cache: 'no-cache',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + auth.token
                },
            })
                .then(async res => {
                    if (!res.ok) {
                        const j = await res.json()
                        throw new Error(j.detail)
                    } else {
                        fetchProduct(product.id, auth.token, setProduct, setError, setTeaser, setDescription, setIsLoaded, reset)
                    }
                },
                    (err) => {
                        setError(err);
                    }
                )
        }
    }
    const fetchTags = useCallback((token, id) => {
        fetch(process.env.REACT_APP_API + '/tags/', {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token
            },
        })
            .then(res => res.json())
            .then(
                (res) => {
                    setTags(res);
                    fetchProduct(id, token, setProduct, setError, setTeaser, setDescription, setIsLoaded, reset);
                },
                (error) => {
                    setError(error)
                }
            )
    }, [reset])
    useEffect(() => {
        fetchCategories(setCategories, setError, function () {
            fetchTags(auth.token, id)
        })
    }, [id, auth, fetchTags])
    return (
        <Container className="d-flex flex-column">
            <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
                <Row className="my-5">
                    <Col>
                        <h1><FontAwesomeIcon icon={faVideo} /> Produkt bearbeiten</h1>
                    </Col>
                    <Col className="text-end pe-4">
                        <Button disabled={submitting} variant="primary" type="submit" onClick={() => {
                            scrollToElement('#submitbutton', {
                                offset: -80,
                                duration: 200
                            })
                        }}>
                            {submitting ? (
                                <Spinner animation="border" variant="light" size="sm"></Spinner>
                            ) : <><FontAwesomeIcon icon={faFloppyDisk} className="me-2" />Speichern</>}
                        </Button>
                    </Col>
                </Row>
                <Row className="w-100">
                    <Col>
                        {isLoaded ? (
                            <>
                                <Row>
                                    <Col lg="8">
                                        <Row>
                                            <Col>
                                                <FloatingLabel
                                                    label="Name *"
                                                    className="mb-3"
                                                >
                                                    <Form.Control {...register('name', { required: true })} defaultValue={product.name} type="text" placeholder="Name" required />
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel
                                                    label="Interne Nr. *"
                                                    className="mb-3"
                                                >
                                                    <Form.Control {...register('serial', { required: true })} defaultValue={product.serial} type="text" placeholder="Interne Nr." required />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel
                                                    label="Kategorie *"
                                                    className="mb-3"
                                                >
                                                    <Form.Select {...register('category_id', { required: true, min: 1 })} aria-label="Kategorie" defaultValue={product.category.id}>
                                                        <option value={0}>bitte wählen</option>
                                                        {categories.map((p, i) => {
                                                            return <option key={'productcategory' + p.id} value={p.id}>{p.name}</option>
                                                        })}
                                                    </Form.Select>
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel
                                                    label="Typ"
                                                    className="mb-3"
                                                >
                                                    <Form.Control {...register('typ', {})} defaultValue={product.typ} type="text" placeholder="Typ" />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className="d-flex" controlId="formBasicCheckbox">
                                                            <Form.Check {...register('disabled', {})} inline value={true} defaultChecked={product.disabled} type="switch" label={<p className="w-100">versteckt</p>} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="d-flex" controlId="formBasicCheckbox">
                                                            <Form.Check {...register('plannable', {})} inline value={true} defaultChecked={product.plannable} type="switch" label={<p className="w-100">im Planer anzeigen</p>} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <FloatingLabel
                                                    label="Cooldown (in Stunden)"
                                                    className="mb-3"
                                                >
                                                    <Form.Control {...register('cooldown', { min: 0 })} defaultValue={product.cooldown} type="number" placeholder="Cooldown" />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col className="text-start">
                                                <Form.Label>Interne Bemerkungen</Form.Label>
                                                <FloatingLabel
                                                    label="Bemerkungen"
                                                    className="mb-3"
                                                >
                                                    <Form.Control {...register('bemerkungen', {})} defaultValue={product.bemerkungen} as="textarea" placeholder="Interne Bemerkungen" />
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col>
                                                <Form.Group className="d-flex" controlId="formBasicCheckbox">
                                                    <Form.Check {...register('setparent', {})} inline value={true} defaultChecked={product.setparent} type="switch" label={<p className="w-100">Produkt-Set</p>} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <a href={process.env.REACT_APP_API + '/products/qr/' + id} target="_blank" rel="noreferrer">
                                            <img className="w-100" alt="QR-Code" src={process.env.REACT_APP_API + '/products/qr/' + id} />
                                        </a>
                                    </Col>
                                </Row>
                                {(watchProductSet || product.setparent) && (
                                    <Row className="mb-4">
                                        <Col>
                                            <h4><FontAwesomeIcon icon={faVideo} /> Produkt-Set</h4>
                                            <Table className="text-start" size="sm" striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>Seriennummer</th>
                                                        <th>Name</th>
                                                        <th>Status</th>
                                                        <th className="text-end">
                                                            <Button className="btn-xs" onClick={() => setShowChildModal(true)}>
                                                                <FontAwesomeIcon icon={faPlus} />
                                                            </Button>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.isArray(product.children) && product.children.map(child => (
                                                        <tr key={"productchildrow" + child.id}>
                                                            <td>{child.serial}</td>
                                                            <td>{child.name}</td>
                                                            <td>{child.disabled ? <FontAwesomeIcon className="text-body-tertiary" icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}</td>
                                                            <td className="text-end">
                                                                <Link to={"/admin/products/" + child.id} title={"Product bearbeiten"}>
                                                                    <button type="button" className="btn btn-primary btn-xs me-2">
                                                                        <FontAwesomeIcon icon={faPen} />
                                                                    </button>
                                                                </Link>
                                                                <Button className="btn-xs" onClick={() => deleteRelation(child.id, child.name + "(" + child.serial + ")")}>
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                )}
                                <Row className="mb-4">
                                    <Col>
                                        <h4><FontAwesomeIcon icon={faBoxesStacked} /> Geräte</h4>
                                        <Table className="text-start" size="sm" striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Seriennummer</th>
                                                    <th>Name</th>
                                                    <th>Status</th>
                                                    <th className="text-end">
                                                        <Link to={"/admin/devices/create/" + product.id} title={"Neues Gerät erstellen"}>
                                                            <button type="button" className="btn btn-primary btn-xs">
                                                                <FontAwesomeIcon icon={faPlus} />
                                                            </button>
                                                        </Link>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(product.devices) && product.devices.map(device => (
                                                    <tr key={"devicerow" + device.id}>
                                                        <td>{device.serial}</td>
                                                        <td>{device.name}</td>
                                                        <td>{device.disabled ? <FontAwesomeIcon className="text-body-tertiary" icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}</td>
                                                        <td className="text-end">
                                                            <Link to={"/admin/devices/" + device.id} title={"Gerät bearbeiten"}>
                                                                <button type="button" className="btn btn-primary btn-xs me-2">
                                                                    <FontAwesomeIcon icon={faPen} />
                                                                </button>
                                                            </Link>
                                                            <Button className="btn-xs" onClick={() => deleteItem(auth.token, product.id, 'devices', device.id, device.serial, setError, fetchProduct, setProduct)}>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>
                                        <Suspense fallback={<Row><Col className="text-center"><Spinner /></Col></Row>}>
                                            <Form.Label>Teaser <small>(max. 150 Zeichen)</small></Form.Label>
                                            <MDEditor
                                                value={teaser}
                                                onChange={validateTeaser}
                                                className={invalidTeaser ? 'is-invalid' : ''}
                                            />
                                            {invalidTeaser && <div className="invalid-feedback text-start">Bitte verwenden Sie maximal 150 Zeichen</div>}
                                        </Suspense>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>
                                        <Suspense fallback={<Row><Col className="text-center"><Spinner /></Col></Row>}>
                                            <Form.Label>Beschreibung</Form.Label>
                                            <MDEditor
                                                value={description}
                                                onChange={setDescription}
                                            />
                                        </Suspense>
                                    </Col>
                                </Row>
                                <Row className="my-4">
                                    <Col>
                                        <Card>
                                            <Card.Body>
                                                <Row>
                                                    {Array.isArray(product.images) && product.images.map((image, i) => (
                                                        <Col key={image + image.id}>
                                                            <AdminImage token={auth.token} setError={setError} i={i} image={image} product={product} />
                                                        </Col>
                                                    ))}
                                                </Row>
                                                <FileUploader classes="dropzone w-100 p-4 mt-4" disabled={uploading} handleChange={(t) => uploadFile(t)} name="image" types={fileTypes} maxSize={8} children={
                                                    <Row>
                                                        <Col xs="12" className="text-center">
                                                            <FontAwesomeIcon icon={faCloudArrowUp} />
                                                            <p>Bild per Drag & Drop hochladen oder</p>
                                                            <Button>Computer durchsuchen</Button>
                                                        </Col>
                                                    </Row>
                                                } />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card>
                                            <Card.Body>
                                                <Card.Title><FontAwesomeIcon icon={faTags} /> Tags</Card.Title>
                                                <Row className="mt-4">
                                                    {tags.map((t, i) => {
                                                        return (
                                                            <Col key={"tag" + t.id}>
                                                                <Form.Group className="text-center" controlId="formBasicCheckbox" key={t.id}>
                                                                    <Form.Check {...register('tags[' + i + ']', {})} inline value={t.id} defaultChecked={isInTag(t.id)} type="switch" label={t.name} />
                                                                </Form.Group>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col>
                                        <h4><FontAwesomeIcon icon={faFilm} /> Videos</h4>
                                        <Table className="text-start" size="sm" striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Link</th>
                                                    <th className="text-end">
                                                        <Button className="btn-xs" onClick={() => setShowVideoModal(true)}>
                                                            <FontAwesomeIcon icon={faPlus} />
                                                        </Button>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(product.videos) && product.videos.map(video => (
                                                    <tr key={"video" + video.id}>
                                                        <td>{video.name}</td>
                                                        <td><a href={video.url} target="_blank" rel="noreferrer">{video.url}</a></td>
                                                        <td className="text-end">
                                                            <Button className="btn-xs" onClick={() => deleteItem(auth.token, product.id, 'videos', video.id, video.name, setError, fetchProduct, setProduct)}>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col>
                                        <h4><FontAwesomeIcon icon={faBook} /> Manuals</h4>
                                        <Table className="text-start" size="sm" striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Link</th>
                                                    <th className="text-end"><Button className="btn-xs" onClick={() => setShowManualModal(true)}>
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </Button></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(product.videos) && product.manuals.map(manual => {
                                                    let url = manual.url;
                                                    console.log(manual.name)
                                                    if (url === null) {
                                                        url = process.env.REACT_APP_DOMAIN + "ul/manuals/" + manual.id + '.' + manual.name.split('.').pop();
                                                    }
                                                    return (
                                                        <tr key={"manual" + manual.id}>
                                                            <td>{manual.name}</td>
                                                            <td><a href={url} target="_blank" rel="noreferrer">{url}</a></td>
                                                            <td className="text-end">
                                                                <Button className="btn-xs" onClick={() => deleteItem(auth.token, product.id, 'manuals', manual.id, manual.name, setError, fetchProduct, setProduct)}>
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>

                                <p className="text-end mt-2">
                                    <small>* Pflichtfelder</small>
                                </p>
                                {error && (
                                    <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true} key={errorid}>
                                        <Alert variant="danger">
                                            <span>
                                                {error.message} {error.detail}
                                            </span>
                                        </Alert>
                                    </FlashMessage>
                                )}
                                {saved && (
                                    <FlashMessage duration={2000}>
                                        <Alert variant="success">
                                            <span>
                                                Produkt erfolgreich aktualisiert
                                            </span>
                                        </Alert>
                                    </FlashMessage>
                                )}
                                <div className="d-grid gap-2">
                                    <Button id="submitbutton" disabled={submitting} variant="primary" type="submit">
                                        {submitting ? (
                                            <Spinner animation="border" variant="light" size="sm"></Spinner>
                                        ) : <><FontAwesomeIcon icon={faFloppyDisk} className="me-2" />Speichern</>}
                                    </Button>
                                </div>
                            </>
                        ) : <div className="text-center">
                            <Spinner />
                        </div>
                        }
                    </Col>
                </Row>
            </form>
            <ProductChildModal product={product} show={showChildModal} setShow={setShowChildModal} fetchProduct={fetchProduct} setProduct={setProduct} setIsLoaded={setIsLoaded} />
            <VideoModal product={product} show={showVideoModal} setShow={setShowVideoModal} fetchProduct={fetchProduct} setProduct={setProduct} setIsLoaded={setIsLoaded} />
            <ManualModal product={product} show={showManualModal} setShow={setShowManualModal} fetchProduct={fetchProduct} setProduct={setProduct} setIsLoaded={setIsLoaded} />
        </Container >
    )
}