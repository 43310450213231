import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router"
import { Alert, Spinner, Container, FloatingLabel, Form, Button, Row, Col } from 'react-bootstrap'
import { faBox } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from '../../components/Auth'
import FlashMessage from '../../components/FlashMessage'
import { fetchProducts } from '../../requests/Products'
import { fetchLocations } from '../../requests/Locations'

export function DeviceCreate() {
    let auth = useAuth();
    let { product_id } = useParams()
    const navigate = useNavigate()
    const { register, handleSubmit, reset } = useForm()
    const [submitting, setSubmitting] = useState(false)
    const [device, setDevice] = useState([])
    const [products, setProducts] = useState([])
    const [locations, setLocations] = useState([])
    const [saved, setSaved] = useState(null)
    const [error, setError] = useState(null)
    const [errorid, setErrorId] = useState(0)

    const onSubmit = data => {
        setSubmitting(true)
        fetch(process.env.REACT_APP_API + '/devices/', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(async res => {
            if (!res.ok) {
                const j = await res.json()
                throw new Error(j.detail)
            }
            return res.json()
        }).then(t => {
            setSubmitting(false)
            setSaved(true)
            setDevice(t)
            setTimeout(function () {
                if (product_id) {
                    navigate({ pathname: "/admin/products/" + product_id });
                } else {
                    navigate({ pathname: "/admin/devices/" });
                }
            }, 1000);
        }).catch((e) => {
            setError({ message: e.name, detail: e.message });
            setErrorId(errorid + 1)
            setSubmitting(false)
        })
    }
    useEffect(() => {
        fetchLocations(auth.token, setLocations, setError, function () {
            fetchProducts(auth.token, setProducts, setError, function () {
                reset({
                    product_id: product_id,
                })
            });
        })
    }, [auth.token, product_id, reset])
    return (
        <Container className="d-flex flex-column">
            <h1 className="my-5"><FontAwesomeIcon icon={faBox} /> Gerät anlegen</h1>
            <Row className="w-100">
                <Col>
                    <form className="form-signin text-center w-100" onSubmit={handleSubmit(onSubmit)}>
                        <FloatingLabel
                            label="Produkt *"
                            className="mb-3"
                        >
                            <Form.Select {...register('product_id', { required: true, min: 1 })} defaultValue={product_id} aria-label="Produkt">
                                <option value={0}>bitte wählen</option>
                                {products.map((p, i) => {
                                    return <option key={'productdevice' + p.id} value={p.id}>{p.name}</option>
                                })}
                            </Form.Select>
                        </FloatingLabel>
                        <FloatingLabel
                            label="Name *"
                            className="mb-3"
                        >
                            <Form.Control {...register('name', { required: true })} defaultValue={device.name} type="text" placeholder="Name" required />
                        </FloatingLabel>
                        <FloatingLabel
                            label="Seriennummer"
                            className="mb-3"
                        >
                            <Form.Control {...register('serial')} defaultValue={device.serial} type="text" placeholder="Seriennummer" />
                        </FloatingLabel>
                        <FloatingLabel
                            label="Inventarnummer"
                            className="mb-3"
                        >
                            <Form.Control {...register('inventar')} defaultValue={device.serial} type="text" placeholder="Inventarnummer" />
                        </FloatingLabel>
                        <FloatingLabel
                            label="Standort *"
                            className="mb-3"
                        >
                            <Form.Select {...register('location_id', { required: true, min: 1 })} aria-label="Standort" defaultValue={products.location_id}>
                                <option value={0}>bitte wählen</option>
                                {locations.map((l, i) => {
                                    return <option key={'devicelocation' + l.id} value={l.id}>{l.name}</option>
                                })}
                            </Form.Select>
                        </FloatingLabel>
                        <Form.Group className="text-start" controlId="formBasicCheckbox">
                            <Form.Check {...register('disabled', {})} inline value={true} defaultChecked={true} type="switch" label={"Deaktiviert"} />
                        </Form.Group>
                        <p className="text-end">
                            <small>* Pflichtfelder</small>
                        </p>
                        {error && (
                            <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true} key={errorid}>
                                <Alert variant="danger">
                                    <span>
                                        {error.message} {error.detail}
                                    </span>
                                </Alert>
                            </FlashMessage>
                        )}
                        {saved && (
                            <FlashMessage duration={2000}>
                                <Alert variant="success">
                                    <span>
                                        Gerät erfolgreich angelegt
                                    </span>
                                </Alert>
                            </FlashMessage>
                        )}
                        <div className="d-grid gap-2">
                            <Button disabled={submitting} variant="primary" type="submit">
                                {submitting ? (
                                    <Spinner animation="border" variant="light" size="sm"></Spinner>
                                ) : "Anlegen"}
                            </Button>
                        </div>
                    </form>
                </Col>
            </Row>
        </Container>
    )
}