import React, { useEffect, useState, useCallback } from "react"
import { Alert, Container, Row, Col, Form, Spinner } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useParams } from "react-router"
import { fetchCategories } from '../requests/Categories'
import { fetchTags } from '../requests/Tags'
import { ProductCard } from '../components/ProductCard'
import FlashMessage from '../components/FlashMessage'
import { devlog } from '../components/Util'

export function Products() {
    const { category_id, tag_id } = useParams()
    const defaultTags = {}
    if (tag_id > 0) {
        defaultTags[parseInt(tag_id)] = true
    }
    const [products, setProducts] = useState([])
    const [lastTotal, setLastTotal] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [search, setSearch] = useState("")
    const [activetags, setActivetags] = useState(defaultTags)
    const [isLoaded, setIsLoaded] = useState(false)
    const [skip, setSkip] = useState(0)
    const [tags, setTags] = useState([])
    const [categories, setCategories] = useState([])
    const [activecategory, setActivecategory] = useState(category_id > 0 ? category_id : 0)
    const [error, setError] = useState(null)
    const itemsPerPage = 20

    const onPageChange = (data) => {
        setSkip(data.selected * itemsPerPage);
    }
    const fetchCatalogue = useCallback((abortController) => {
        if (lastTotal > Date.now() - 1000) {
            var uri = process.env.REACT_APP_API + '/products/listing?order_by=name&asc=1&skip=' + skip + '&limit=' + itemsPerPage;
            if (search && search.length > 0) {
                uri += '&fltr=' + encodeURIComponent(search)
            }
            if (Object.keys(activetags).length > 0) {
                uri += '&tags=' + encodeURIComponent(Object.keys(activetags).join(','))
            }
            if (activecategory > 0) {
                uri += '&category_id=' + activecategory
            }
            fetch(uri, {
                signal: abortController.signal,
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
            })
                .then(res => res.json())
                .then(
                    (res) => {
                        if (res.detail) {
                            setError({ message: res.detail })
                        } else {
                            setProducts(res)
                        }
                        setIsLoaded(true)
                    },
                    (error) => {
                        setIsLoaded(true)
                        if (abortController.signal.aborted) {
                            devlog('The user aborted the request');
                        } else {
                            setError(error)
                        }
                    }
                )
        } else {
            uri = process.env.REACT_APP_API + '/products/total?only_visible=true';
            if (search && search.length > 0) {
                uri += '&fltr=' + encodeURIComponent(search)
            }
            if (Object.keys(activetags).length > 0) {
                uri += '&tags=' + encodeURIComponent(Object.keys(activetags).join(','))
            }
            fetch(uri, {
                signal: abortController.signal,
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
            }).then(res => res.json())
                .then(
                    (result) => {
                        setPageCount(Math.ceil(result / itemsPerPage));
                        setLastTotal(Date.now());
                    },
                    (error) => {
                        if (abortController.signal.aborted) {
                            devlog('The user aborted the request');
                        } else {
                            setError(error)
                        }
                    }
                )
        }
    }, [activetags, activecategory, search, skip, lastTotal])

    const toggleTag = (t) => {
        const abortController = new AbortController();
        var newtags = activetags;
        if (t in activetags) {
            delete newtags[t]
        } else {
            newtags[t] = true
        }
        setActivetags(newtags)
        setSkip(0)
        fetchCatalogue(abortController)
        return () => {
            abortController.abort();
        }
    }
    useEffect(() => {
        const abortController = new AbortController()
        fetchTags(abortController, setTags, setError)
        fetchCategories(setCategories, setError)
        fetchCatalogue(abortController)
        return () => {
            abortController.abort();
        }
    }, [fetchCatalogue])
    if (!isLoaded) {
        return <Row>
            <Col className="text-center">
                <Spinner />
            </Col>
        </Row>
    }
    return (<Container>
        {error && (
            <FlashMessage duration={parseInt(process.env.REACT_APP_ALERT_DURATION)} persistOnHover={true}>
                <Alert variant="danger">
                    <span>
                        {error.message} {error.detail}
                    </span>
                </Alert>
            </FlashMessage>
        )}
        <Row>
            <Col>
                <h2 className="mb-4 mt-2">Tags</h2>
            </Col>
            {categories.length > 0 && (
                <Col>
                    <Form.Select defaultValue={parseInt(activecategory)} onChange={(e) => { setLastTotal(0); setActivecategory(e.target.value) }}>
                        <option value="0">alle Kategorien</option>
                        {categories.map((c, i) => {
                            return (
                                <option key={"category" + c.id} value={c.id}>{c.name}</option>
                            )
                        })}
                    </Form.Select>
                </Col>
            )}
            <Col>
                <input className="form-control" type="text" name="search" placeholder="Suche" onChange={(e) => { setLastTotal(0); setSearch(e.target.value); }} />
            </Col>
        </Row><Row>
            {tags.map((t, i) => {
                return (
                    <Col key={"tag" + t.id}>
                        <Form.Group className="text-center" controlId="formBasicCheckbox" key={t.id}>
                            <Form.Check onChange={(e) => { toggleTag(e.currentTarget.value) }} inline value={t.id} defaultChecked={Object.keys(activetags).includes(t.id.toString())} type="switch" label={t.name} />
                        </Form.Group>
                    </Col>
                )
            })}
        </Row>
        <Row xs={1} md={2} lg={3} className="g-4 my-4 card-group">
            {products.map((p) => {
                return (
                    <Col key={"productcard" + p.id} >
                        <ProductCard product={p} />
                    </Col>
                )
            })}
        </Row>
        {pageCount > 1 && (
            <nav>
                <ReactPaginate
                    previousLabel={'zurück'}
                    nextLabel={'weiter'}
                    breakLabel={'...'}
                    breakClassName={'break-me page-link'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={skip / itemsPerPage}
                    onPageChange={onPageChange}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    subContainerClassName={'pages pagination'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </nav>
        )}
    </Container>)
}